// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import styled from 'styled-components';
import media from 'styled-media-query';

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.blue};
  margin-top: 6rem;
  display: grid;
  place-items: center;
  font-size: 1.6rem;
  line-height: 3.2rem;
`;

export const FooterContent = styled.div`
  width: 100vw;
  display: flex;
  display: grid;
  place-items: center;

  ${media.greaterThan('810px')`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  max-width: 80%;
  margin-top: 4rem;
  `}
`;

export const ListContainer = styled.div<{ contact?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 156px;

  &:last-child {
    align-items: center;
  }

  &:first-child {
    margin-top: 3.8rem;
  }

  ${media.greaterThan('810px')`
  align-items: flex-start;
  width: 100%;

  &:first-child {
    margin-top: 0rem;
  }
`}
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 800;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`;

export const RiderizeLogo = styled.span`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1.6rem;
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  display: inline;

  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }
`;

export const SocialIcon = styled.img`
  position: relative;
  top: 2px;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const RoundDiv = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: grid;
  place-items: center;
`;

export const RiderizeInfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97.9%;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  margin-top: 3.2rem;
`;

export const RiderizeText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  width: 220px;

  ${media.greaterThan('810px')`
    width: 100%;

    &:after {
      content: ' - 38.495.103/0001-42';
    }
  `}
`;

export const Text = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  width: 220px;

  ${media.greaterThan('810px')`
  margin: 0;
  width: 100%;
`}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  ${media.greaterThan('810px')`
  width: 80%;
  flex-direction: row;
`}
`;
