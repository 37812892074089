import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const ButtonOverlay = styled.button`
  position: fixed;
  right: 1.6rem;
  bottom: 8.8rem;

  padding-top: 3.2rem;
  padding-bottom: 1.6rem;
  width: 16rem;
  background: ${({ theme }) => theme.colors.blue};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  border: none;

  cursor: default;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  z-index: 100;

  p {
    color: white;
    margin-top: 0.8rem;
    font-size: 1.4rem;
  }

  animation: appearDownloadAppButton 250ms ease-out forwards;
  @keyframes appearDownloadAppButton {
    from {
      visibility: hidden;
      opacity: 0;
      transform: translateY(15rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;

export const CloseButton = styled(CloseIcon)`
  font-size: 2.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
`;
