import styled from 'styled-components';

export const ButtonOverlay = styled.button`
  position: fixed;
  right: 0;
  bottom: 8.8rem;

  height: 6.5rem;
  width: 4rem;
  background: ${({ theme }) => theme.colors.blue};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem 0 0 1.2rem;
  border: none;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;

  z-index: 100;

  animation: appearDownloadAppButton 250ms ease-out forwards;
  @keyframes appearDownloadAppButton {
    from {
      visibility: hidden;
      opacity: 0;
      transform: translateY(15rem);
    }
    to {
      opacity: 0.7;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;
