import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useAuthentication } from '@source/contexts/AuthenticationContext';
import { useRouter } from 'next/router';
import type { LoginResponse } from '@source/graphql/autogenerate/schemas';
import { useCartController, useClearCart } from '@source/recoil/hooks';
import {
  useListUserWorkshopsQuery,
  useUserCompaniesQuery
} from '@source/graphql/autogenerate/hooks';

type ProfileInformationProps = {
  session: LoginResponse;
  fontColor: string;
  voucherValue?: number;
};

const ProfileInformation: React.FC<ProfileInformationProps> = ({
  session,
  fontColor,
  voucherValue
}): JSX.Element => {
  const { data: { userCompanies = [] } = {} } = useUserCompaniesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only'
  });
  const { data: { listUserWorkshops = [] } = {} } = useListUserWorkshopsQuery();

  const userHasWorkshops = !!listUserWorkshops && !!listUserWorkshops.length;
  const userIsManager = !!userCompanies && !!userCompanies.length;

  const settings = [
    ...(userIsManager ? ['Painel do Organizador'] : []),
    ...(userHasWorkshops ? ['Painel da Oficina'] : []),
    'Comprar Voucher',
    'Logout'
  ];

  const [anchorElUser, setAnchorElUser] = useState(null);
  const { signOut } = useAuthentication();
  const cartController = useCartController();
  const clearCart = useClearCart();
  const router = useRouter();

  const buyVoucher = () => {
    clearCart();
    cartController({ type: 'voucher' });

    router.push(
      {
        pathname: `/checkout/voucher`,
        query: {
          voucherValue
        }
      },
      `/checkout/voucher`
    );
  };

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = async (setting: string) => {
    setAnchorElUser(null);

    if (setting === 'Dashboard') {
      router.push(`/dashboard/home`);
    }

    if (setting === 'Painel da Oficina') {
      router.push(`${process.env.NEXT_PUBLIC_BASE_URL}workshop/dashboard`);
    }
    if (setting === 'Painel do Organizador') {
      window.open(
        process.env.NEXT_PUBLIC_BASE_URL === 'https://www.riderize.com/'
          ? `https://v1.riderize.com/dashboard/challenges/list`
          : `https://v1-staging.riderize.com/dashboard/challenges/list`
      );
    }

    if (setting === 'Comprar Voucher') {
      buyVoucher();
    }

    if (setting === 'Logout') {
      signOut();
    }
  };

  return (
    <>
      <Tooltip title="Abrir menu">
        <Button
          onClick={handleOpenUserMenu}
          sx={{ p: 0, textTransform: 'capitalize' }}
        >
          <Typography
            mr={2}
            sx={{
              fontSize: '1.6rem',
              fontWeight: '600',
              color: fontColor,
              display: { xs: 'none', sm: 'flex' }
            }}
          >
            {session?.user
              ? session?.user.firstname + ' ' + session?.user.lastname
              : null}
          </Typography>

          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Avatar
            alt="Foto de perfil"
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/${session?.profile?.profile_avatar}`}
          />
        </Button>
      </Tooltip>

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map(setting => (
          <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProfileInformation;
