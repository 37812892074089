const CheckIcon = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={10}
      fill="#19A949"
      fillOpacity={props.customFillOpacity || 0.16}
    />
    <path
      d="M6 9.083 8.947 12 14 7"
      stroke={props.customFillOpacity ? '#fff' : '#19A949'}
      strokeWidth={2}
    />
  </svg>
);

export default CheckIcon;
