import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './themes';

type ThemeType = {
  children: React.ReactNode;
};

const Theme = ({ children }: ThemeType) => {
  const isDarkThemeSelected = false;
  const selectedTheme = isDarkThemeSelected ? darkTheme : lightTheme;

  return <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>;
};

export default Theme;
