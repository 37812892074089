import { useAuthentication } from '@source/contexts/AuthenticationContext';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

const SentryUserManager = () => {
  const { session } = useAuthentication();

  useEffect(() => {
    if (session) {
      Sentry.setUser({
        id: session?.user?.id ?? undefined,
        email: session?.user?.email ?? undefined,
        username:
          `${session?.user?.firstname} ${session?.user?.lastname}` ?? undefined
      });
    } else {
      Sentry.setUser(null);
    }
  }, [session]);

  return null;
};

export default SentryUserManager;
