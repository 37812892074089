import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type CustomAlertProps = {
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  severity: 'error' | 'success' | 'warning' | 'info';
  message: string;
};

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  autoHideDuration = 3000,
  onClose,
  severity,
  message
}): JSX.Element => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default CustomAlert;
