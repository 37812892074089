type Props = {
  step: number;
  status?: string | undefined;
};

const LabelIcon: React.FC<Props> = ({ step, status }): JSX.Element => {
  return (
    <div
      style={{
        backgroundImage:
          status === 'disabled'
            ? `url('/icons/grey-ellipse.svg')`
            : `url('/icons/blue-ellipse.svg')`,
        color: status === 'disabled' ? '#565c66' : '#fff',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '2rem',
        display: 'grid',
        placeItems: 'center',
        fontSize: '1.2rem',
        height: '2rem'
      }}
    >
      {step}
    </div>
  );
};

export default LabelIcon;
