import ResponsiveAppBar from '@components/ResponsiveAppBar';
import Footer from '@components/Footer';

import * as S from './styles';

type MainLayoutProps = {
  renderErrorPage?: boolean;
  children: React.ReactNode;
};

const MainLayout = ({ renderErrorPage, children }: MainLayoutProps) => {
  return (
    <S.Container>
      <ResponsiveAppBar renderErrorPage={renderErrorPage} />
      <S.Content>{children}</S.Content>
      <Footer />
    </S.Container>
  );
};

export default MainLayout;
