/* eslint-disable consistent-return */
import CryptoJS from 'crypto-js';

import { FIFTEEN_DAYS } from '@utils/magicTime';
import type { LoginResponse } from '@source/graphql/autogenerate/schemas';

const TOKEN_SECRET = 'dcaefdeaa06ec681f658fcdb2f887e34';

export function sealSession(user: LoginResponse | any) {
  try {
    const createdAt = Date.now();

    const dataToEncrypt = { ...user, createdAt, maxAge: FIFTEEN_DAYS };

    const encJson = CryptoJS.AES.encrypt(
      JSON.stringify(dataToEncrypt),
      TOKEN_SECRET
    ).toString();
    const encData = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encJson)
    );
    return encData;
  } catch (error) {
    console.error('sealSession error: ', error);
  }
}

export function unsealSession(encryptedToken: string) {
  try {
    const decData = CryptoJS.enc.Base64.parse(
      encryptedToken as string
    ).toString(CryptoJS.enc.Utf8);
    const bytes = CryptoJS.AES.decrypt(decData, TOKEN_SECRET).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(bytes);
  } catch (error) {
    // console.error('unsealed error: ', error);
  }
}
