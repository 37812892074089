import { v4 as uuidv4 } from 'uuid';
import { useActiveSessionId } from '@source/recoil/activeSessionId/hook';
import { useEffect } from 'react';

type ActiveSessionIdProviderProps = {
  children: React.ReactNode;
};

const ActiveSessionIdProvider: React.FC<ActiveSessionIdProviderProps> = ({
  children
}): JSX.Element => {
  const { setActiveSessionId } = useActiveSessionId();

  useEffect(() => {
    const key = uuidv4();

    const sessionId = {
      key,
      date: new Date()
    };

    setActiveSessionId(JSON.stringify(sessionId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default ActiveSessionIdProvider;
