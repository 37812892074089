import {
  createContext,
  type ReactNode,
  useEffect,
  useState,
  useContext
} from 'react';
import Router, { useRouter } from 'next/router';
import { parseCookies, setCookie } from 'nookies';

import type { LoginResponse } from '@source/graphql/autogenerate/schemas';
import { useLoginMutation } from '@source/graphql/autogenerate/hooks';
import { FIFTEEN_DAYS } from '@utils/magicTime';
import { sealSession, unsealSession } from '@source/lib/auth';
import destroyCookies from '@source/utils/destroyCookies';

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthenticationContextData = {
  signIn({ email, password }: SignInCredentials): Promise<unknown>;
  isUserStaff: boolean | undefined;
  signOut(): void;
  session: LoginResponse | undefined;
  isLoggedIn: boolean;
  redirectUrl: string;
  setRedirectUrl(redirectUrl: string): void;
  getSession: () => void;
};

type AuthenticationProviderProps = {
  children: ReactNode;
};

export const AuthenticationContext = createContext(
  {} as AuthenticationContextData
);

export function AuthenticationProvider({
  children
}: AuthenticationProviderProps) {
  const router = useRouter();
  const [session, setSession] = useState<LoginResponse>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/');

  const isUserStaff = session?.user.staff;

  const [loginMutation] = useLoginMutation();

  const getSession = () => {
    const cookies = parseCookies();

    if (!cookies['riderize@session']) return;

    const session = unsealSession(cookies['riderize@session']);

    const parsedSession = session ? session : null;

    setIsLoggedIn(true);

    setSession(parsedSession);

    return;
  };

  useEffect(() => {
    getSession();
  }, []);

  async function signIn({ email, password }: SignInCredentials) {
    try {
      const { data } = await loginMutation({
        variables: {
          data: { email, password }
        }
      });

      data &&
        setCookie(undefined, 'riderize@accessToken', data.login.accessToken, {
          maxAge: FIFTEEN_DAYS,
          path: '/',
          secure: true
        });

      data &&
        setCookie(undefined, 'riderize@refreshToken', data.login.refreshToken, {
          maxAge: FIFTEEN_DAYS,
          path: '/',
          secure: true
        });

      const sealdSession = sealSession(data?.login as LoginResponse);

      // Set encrypted session cookie
      setCookie(undefined, 'riderize@session', sealdSession as string, {
        maxAge: FIFTEEN_DAYS,
        path: '/',
        secure: true
      });

      getSession();

      window.location.href = redirectUrl;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error('Error signing in');
      }
    }
  }

  async function signOut() {
    const cookiesToDestroy = [
      'riderize@session',
      'riderize@accessToken',
      'riderize@refreshToken',
      'riderize@session-provider',
      'next-auth.callback-url',
      'next-auth.csrf-token',
      'riderize@redirect',
      'cart',
      'riderize@origin-url',
      'riderize@snp',
      'challenge',
      'riderize@temp'
    ];

    destroyCookies(cookiesToDestroy);

    setSession(undefined);
    setIsLoggedIn(false);

    if (router.asPath.startsWith('/checkout')) {
      router.push('/');
    } else {
      Router.reload();
    }
  }

  const providerValues: AuthenticationContextData = {
    session,
    isUserStaff,
    signIn,
    signOut,
    isLoggedIn,
    redirectUrl,
    setRedirectUrl,
    getSession
  };

  return (
    <AuthenticationContext.Provider value={providerValues}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      'useAuthentication must be used within AuthenticationProvider'
    );
  }

  return context;
}
