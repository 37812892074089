import { NextRouter } from 'next/router';
import { IDefaultTheme } from '@source/styles/themes';
import { useTheme } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { useRecoilValue } from 'recoil';
import { challengeSlugDetailsState } from '@source/recoil/atoms';
import { getBranchLink } from '@source/utils/getBranchLink';
import * as S from './styles';
import Link from 'next/link';

type AppDownloadDrawerProps = {
  handleDrawerVisible: (visible: boolean) => void;
  router: NextRouter;
};

const AppDownloadDrawer: React.FC<AppDownloadDrawerProps> = ({
  handleDrawerVisible,
  router
}): JSX.Element => {
  const theme = useTheme() as IDefaultTheme;

  const defaultShareLink = process.env.NEXT_PUBLIC_DEFAULT_SHARE_LINK as string;

  const isChallengeDetailPage = router.pathname.includes('challenge_path');

  const [shareLink, setShareLink] = useState(defaultShareLink);

  const challengeDetail = useRecoilValue(challengeSlugDetailsState);

  const handleCloseDrawer = () => {
    handleDrawerVisible(false);
  };

  useEffect(() => {
    const getNewBranchLink = async () => {
      const newBranchLink = await getBranchLink(challengeDetail);
      setShareLink(newBranchLink);
    };

    if (isChallengeDetailPage && challengeDetail) {
      getNewBranchLink();
    } else {
      setShareLink(defaultShareLink);
    }
  }, [challengeDetail, isChallengeDetailPage, defaultShareLink]);

  return (
    <S.Wrapper>
      <S.CloseButton onClick={handleCloseDrawer}>
        <CloseIcon sx={{ fontSize: '2.2rem', color: theme.colors.gray3 }} />
      </S.CloseButton>

      <S.Content>
        <Image
          src="/icons/riderize-R-blue.svg"
          width={53}
          height={53}
          alt="Logo Riderize"
        />

        <S.ContentTitle>
          <S.Title>Riderize</S.Title>
          <S.TitleHelper>Aplicativo de Ciclismo</S.TitleHelper>
        </S.ContentTitle>

        <Link legacyBehavior passHref href={shareLink}>
          <S.Anchor target="_blank" rel="noreferrer">
            Abrir
          </S.Anchor>
        </Link>
      </S.Content>
    </S.Wrapper>
  );
};

export default AppDownloadDrawer;
