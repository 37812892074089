import { RecoilRoot } from 'recoil';
import dynamic from 'next/dynamic';
import { AuthenticationProvider } from '@contexts/AuthenticationContext';
import { RegisterUserEmailProvider } from '@contexts/RegisterUserEmailContext';
import ErrorBoundary from '@source/components/ErrorBoundary';
import ToastProvider from '@source/components/ToastProvider';
import ActiveSessionIdProvider from '@source/components/ActiveSessionIdProvider';
import DownloadAppDrawerProvider from '@source/components/DownloadAppProvider';
import SentryUserManager from '@source/components/SentryUserManager';
import QrCodeAppDownloadProvider from '@source/components/QrCodeAppDownloadProvider';
import RoutesTransitionProvider from '@source/components/RoutesTransitionProvider';

const ClarityClient = dynamic(
  () => import('@source/components/ClarityClient'),
  {
    ssr: false
  }
);

type ApplicationProviderProps = {
  children: React.ReactNode;
};

const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children
}) => {
  return (
    <AuthenticationProvider>
      <RegisterUserEmailProvider>
        <RecoilRoot>
          <ErrorBoundary>
            <RoutesTransitionProvider>
              <ToastProvider>
                <DownloadAppDrawerProvider>
                  <QrCodeAppDownloadProvider>
                    <ActiveSessionIdProvider>
                      <ClarityClient />
                      <SentryUserManager />
                      {children}
                    </ActiveSessionIdProvider>
                  </QrCodeAppDownloadProvider>
                </DownloadAppDrawerProvider>
              </ToastProvider>
            </RoutesTransitionProvider>
          </ErrorBoundary>
        </RecoilRoot>
      </RegisterUserEmailProvider>
    </AuthenticationProvider>
  );
};

export default ApplicationProvider;
