import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-BoldItalic.ttf') format('tff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat Black Italic';
    src: url('/fonts/Montserrat-BlackItalic.ttf') format('woff2');
    font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-size: 1.6rem;
  }

  html {
    font-size: 62.5%; // 1rem = 10px
    overflow-x: hidden;
  }

  button {
    cursor: pointer;
    border: none;
    background: transparent;
  }

  button[disabled] {
    opacity: 0.4;
    &:hover {
    cursor: not-allowed;
    }
  }

  ul {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  p {
    @media (max-width: 375px) {
      font-size: 1.4rem;
    }
  }
`;
