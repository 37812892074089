import type { Challenge } from '@source/graphql/autogenerate/schemas';
import { api } from '@source/services/api';
import he from 'he';

export const getBranchLink = async (challenge: Challenge): Promise<string> => {
  const { id, name, description, image_avatar, slug_name, short_id, company } =
    challenge;

  const defaultShareLink = `${process.env.NEXT_PUBLIC_BASE_URL}challenges/${company?.profile?.username}/${slug_name}-${short_id}`;

  const linkData = {
    campaign: 'Share challenge',
    channel: 'RNApp',
    feature: 'share',
    stage: 'new user',
    tags: [`${slug_name}`],
    alias: '',
    data: {
      custom_data: true,
      challenge_id: id!,
      screenName: 'Challenge.Description',
      custom_int: new Date(),
      $og_title: name,
      $og_description: he.decode(description).replace(/<[^>]*>/g, ''),
      $og_image_url: `${process.env.NEXT_PUBLIC_CDN_URL}/${image_avatar}?wdt=120&hgt=120`,
      $desktop_url: `${process.env.NEXT_PUBLIC_BASE_URL}challenges/${company?.profile?.username}/${slug_name}-${short_id}`,
      $uri_redirect_mode: 2
    }
  };

  try {
    const { data: branchLink } = await api.post('/branch', linkData);
    return branchLink;
  } catch (error) {
    return defaultShareLink;
  }
};
