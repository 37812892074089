import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as S from './styles';

type ErrorPageProps = {
  errorMessage: string;
  handleErrorBoundary?: () => void;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage,
  handleErrorBoundary
}): JSX.Element => {
  const router = useRouter();

  const manageErrorBoundary = () => {
    if (handleErrorBoundary) {
      return handleErrorBoundary();
    }
    return;
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', manageErrorBoundary);

    return () => {
      router.events.off('routeChangeComplete', manageErrorBoundary);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Title> Uh oh...</S.Title>
      <S.Text>{errorMessage}</S.Text>
      <Image src="/images/404.svg" width={504} height={350} alt="" />
      <Link legacyBehavior href="/" passHref>
        <S.Anchor>Ir para a Home</S.Anchor>
      </Link>
    </S.Container>
  );
};

export default ErrorPage;
