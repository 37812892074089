import { pt } from '@source/locales/portuguese/footer';
import router from 'next/router';
import Link from 'next/link';
import * as S from './styles';
import Image from 'next/legacy/image';

export default function Footer() {
  const translation = pt.footer;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <S.Footer id="contato">
      <S.FooterContent>
        <S.ListContainer>
          <S.Title>{translation.links}</S.Title>

          <S.List>
            <li>
              <S.Button onClick={scrollToTop}>
                {translation.back_to_top}
              </S.Button>
            </li>

            <li>
              <Link legacyBehavior passHref href="/legal/terms">
                <S.Link target="_blank">{translation.terms}</S.Link>
              </Link>
            </li>

            <li>
              <Link legacyBehavior passHref href="/legal/terms-workshops">
                <S.Link target="_blank">{translation.terms_workshop}</S.Link>
              </Link>
            </li>

            <li>
              <Link legacyBehavior passHref href="/legal/privacy">
                <S.Link target="_blank">{translation.privacy}</S.Link>
              </Link>
            </li>

            <li>
              <Link
                legacyBehavior
                passHref
                href="/planos-assinatura-clube-beneficios-lojas-oficinas-bicicleta"
              >
                <S.Link target="_blank">
                  {translation.subscription_plans}
                </S.Link>
              </Link>
            </li>

            <li>
              <S.Button
                onClick={() => router.push('/criar-evento-de-ciclismo')}
              >
                {translation.create_event}
              </S.Button>
            </li>
          </S.List>
        </S.ListContainer>

        <S.ListContainer contact>
          <S.Title>{translation.contact}</S.Title>

          <S.List>
            <li>
              <S.Text style={{ textAlign: 'left' }}>
                Suporte via WhatsApp: (47) 9749-7363
              </S.Text>
            </li>
            <li>
              <S.Text style={{ textAlign: 'left' }}>
                Contato Comercial via WhatsApp: (47) 9677-2994
              </S.Text>
            </li>

            <li>
              <Link legacyBehavior passHref href="mailto:contato@riderize.com">
                <S.Link>contato@riderize.com</S.Link>
              </Link>
            </li>

            <li>
              <S.Text style={{ textAlign: 'left' }}>
                Av. Oscar Barcelos 686 Centro, Rio do Sul - SC CEP 89160-027
              </S.Text>
            </li>
          </S.List>
        </S.ListContainer>

        <S.ListContainer>
          <S.RiderizeLogo>Riderize</S.RiderizeLogo>
          <S.SocialIconsContainer>
            <S.RoundDiv>
              <Link
                legacyBehavior
                href="https://www.instagram.com/riderize/"
                passHref
              >
                <a target="_blank" rel="noopener">
                  <S.SocialIcon
                    loading="lazy"
                    src="/icons/instagram.svg"
                    alt="Instagram"
                    width={16}
                    height={16}
                  />
                </a>
              </Link>
            </S.RoundDiv>

            <S.RoundDiv>
              <Link
                legacyBehavior
                href="https://www.linkedin.com/company/riderize/"
                passHref
              >
                <a target="_blank" rel="noopener">
                  <S.SocialIcon
                    loading="lazy"
                    src="/icons/linkedin.svg"
                    alt="LinkedIn"
                    width={16}
                    height={16}
                  />
                </a>
              </Link>
            </S.RoundDiv>

            <S.RoundDiv>
              <Link
                legacyBehavior
                href="https://www.facebook.com/RiderizeApp/"
                passHref
              >
                <a target="_blank" rel="noopener">
                  <S.SocialIcon
                    loading="lazy"
                    src="/icons/facebook-f.svg"
                    alt="Facebook"
                    width={16}
                    height={16}
                  />
                </a>
              </Link>
            </S.RoundDiv>
          </S.SocialIconsContainer>

          <S.Title style={{ marginTop: '2.4rem' }}>APOIO</S.Title>
          <Image
            src={
              'https://cdn.riderize.com/certificados/fapesc_monocromatica.png'
            }
            loader={({ src }) => src}
            width={230}
            height={70}
            alt="Apoio FAPESC"
          />
        </S.ListContainer>
      </S.FooterContent>

      <S.RiderizeInfoSection>
        <S.Wrapper>
          <S.RiderizeText>
            ®{new Date().getFullYear()} Riderize Tecnologia LTDA.
          </S.RiderizeText>

          <S.Text>{translation.rights}</S.Text>
        </S.Wrapper>
      </S.RiderizeInfoSection>
    </S.Footer>
  );
}
