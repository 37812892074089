import styled from 'styled-components';
import media from 'styled-media-query';

export const AnchorTag = styled.a<{
  path: string;
  page: string;
  fontColor?: string;
}>`
  && {
    color: ${({ fontColor, theme }) =>
      fontColor ? fontColor : theme.colors.gray1};
    padding: 0.8rem;
    display: block;
    border-radius: 0;
    border-bottom: ${({ path, page, theme }) =>
      path === page ? `2px solid ${theme.colors.yellow}}` : 'none'};
    transition: all 0.3s ease;
  }
`;

export const VoucherButton = styled.button<{
  bgcolor: string;
  visibility: string;
}>`
  background: transparent;
  border: ${({ bgcolor, theme }) =>
    bgcolor === '#0564ff'
      ? `2px solid ${theme.colors.blueAccent}`
      : `2px solid ${theme.colors.gray3}`};
  color: ${({ bgcolor, theme }) =>
    bgcolor === '#0564ff' ? theme.colors.white : theme.colors.blue};
  margin-right: 1.6rem;
  border-radius: 10rem;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;

  @media (max-width: 1279px) {
    display: ${({ visibility }) =>
      visibility === 'hide-desktop' ? 'block' : 'none'};
  }
  @media (min-width: 1280px) {
    display: ${({ visibility }) =>
      visibility === 'hide-mobile' ? 'block' : 'none'};
  }

  transition: all 0.3s ease;

  &:hover {
    background: ${({ bgcolor, theme }) =>
      bgcolor === '#0564ff' ? theme.colors.blueAccent : theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }

  ${media.greaterThan('medium')`
    font-size: 1.6rem;
  `}
`;

export const LoginButton = styled.div<{
  bgcolor: string;
  visibility: string;
}>`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ bgcolor, theme }) =>
    bgcolor === '#0564ff' ? theme.colors.blueAccent : theme.colors.blue};
  border: solid 2px transparent;
  margin-right: 1.6rem;
  border-radius: 10rem;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 1279px) {
    display: ${({ visibility }) =>
      visibility === 'hide-desktop' ? 'block' : 'none'};
  }
  @media (min-width: 1280px) {
    display: ${({ visibility }) =>
      visibility === 'hide-mobile' ? 'block' : 'none'};
  }

  transition: all 0.3s ease;

  &:hover {
    background: ${({ bgcolor, theme }) =>
      bgcolor === '#0564ff' ? theme.colors.blue : theme.colors.white};
    color: ${({ bgcolor, theme }) =>
      bgcolor === '#0564ff' ? theme.colors.white : theme.colors.blue};
    border: ${({ bgcolor, theme }) =>
      bgcolor === '#0564ff'
        ? '2px solid transparent'
        : `2px solid ${theme.colors.gray3}`};
  }

  ${media.greaterThan('medium')`
    font-size: 1.6rem;
  `}
`;
