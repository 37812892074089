// import { Box, Stepper, Step, StepLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import StepIcon from '@components/Navbar/StepIcon';
import { useCartAndStepsController } from '@source/recoil/hooks';
import orderBy from 'lodash/orderBy';

type StepsProps = {
  customSteps?: { label: string; step: number; status: string }[];
};

const Steps: React.FC<StepsProps> = (): JSX.Element => {
  const { cartAndStepsController } = useCartAndStepsController();

  const steps = orderBy(cartAndStepsController, ['step'], ['asc']);

  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '60%'
        }}
      >
        <Stepper
          sx={{
            width: '100%',
            '& .MuiStepConnector-line': {
              borderTopStyle: 'dashed',
              borderTopColor: 'green'
            }
          }}
        >
          {steps.map(stepItem => {
            return (
              <Step key={stepItem.step}>
                <StepLabel
                  StepIconComponent={() => <StepIcon stepItem={stepItem} />}
                >
                  <span
                    style={{
                      color: '#565C66',
                      fontSize: '1.6rem'
                    }}
                  >
                    {stepItem.label}
                  </span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </>
  );
};

export default Steps;
