import { QueryClient } from '@tanstack/react-query';

const reactQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity
      }
    }
  });

export default reactQueryClient();
