import { globalRouteTransitionState } from '@source/recoil/atoms';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

const useRoutesTransition = () => {
  const router = useRouter();

  const [isLocalState, setIsLocalState] = useState<boolean>(false);

  const [globalRouteTransition, setGlobalRouteTransition] = useRecoilState(
    globalRouteTransitionState
  );

  const handleRouteChangeStart = (url: string) => {
    if (url !== router.asPath) {
      setGlobalRouteTransition(true);
    }
  };

  const handleRouteChangeEnd = (url: string) => {
    if (url === router.asPath) {
      setIsLocalState(false);
      setGlobalRouteTransition(false);
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  });

  const handleRouteTransition = () => {
    setIsLocalState(true);
  };

  return {
    globalRouteTransition,
    routeTransition: globalRouteTransition && isLocalState,
    handleRouteTransition
  };
};

export default useRoutesTransition;

// globalRouteTransition works on every element on a page during the route transition event
// you can invoke it as you please, without any sort of trigger dependency, for example:
// <input disabled={globalRouteTransition} />

// routeTransition works on a specific element during the route transition event. Use handleRouteTransition
// as a dependency of this same element, for example:
// <button onClick={()=>handleRouteTransition()} disabled={routeTransition}></button>
