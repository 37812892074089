export const pt = {
  footer: {
    links: 'Links úteis',
    back_to_top: 'Voltar ao topo',
    terms: 'Termos de uso',
    terms_workshop: 'Termos da oficina',
    privacy: 'Política de privacidade',
    subscription_plans: 'Crie planos de assinatura',
    create_event: 'Criar evento de ciclismo',
    contact: 'Contato',
    rights: 'Todos os direitos reservados.'
  }
};
