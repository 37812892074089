import { createContext, useState, useContext } from 'react';

type RegisterUserEmailType = {
  children: React.ReactNode;
};

type UserInfo = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  phone: string;
  ddi: string;
  emailCode: string;
  phoneCode: string;
};

type RegisterUserData = {
  userRegisterData: UserInfo;
  setUserRegisterData: React.Dispatch<React.SetStateAction<UserInfo>>;
};

export const RegisterUserEmailContext = createContext({} as RegisterUserData);

export function RegisterUserEmailProvider({ children }: RegisterUserEmailType) {
  const [userRegisterData, setUserRegisterData] = useState({} as UserInfo);

  const registerUserData: RegisterUserData = {
    userRegisterData,
    setUserRegisterData
  };

  return (
    <RegisterUserEmailContext.Provider value={registerUserData}>
      {children}
    </RegisterUserEmailContext.Provider>
  );
}

export function useRegisterUserEmail() {
  const context = useContext(RegisterUserEmailContext);

  if (!context) {
    throw new Error(
      'useAuthentication must be used within AuthenticationProvider'
    );
  }

  return context;
}
