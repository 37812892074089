export interface IDefaultTheme {
  colors: {
    hotPink: string;
    purple: string;
    lightPurple: string;
    blue: string;
    auxiliaryBlue: string;
    auxiliaryBlueLight: string;
    blueAccent: string;
    navyBlue: string;
    mayaBlue: string;
    superBlueLight: string;
    darkBlue: string;
    aliceBlue: string;
    lightCyan: string;
    lightYellow: string;
    yellow: string;
    yellowAccent: string;
    gold: string;
    goldAccent: string;
    black: string;
    blackText: string;
    modalBackground: string;
    pageBackground: string;
    grayBorder: string;
    grayWorkshopDashboard: string;
    grayPlaceholder: string;
    gray1: string;
    gray2: string;
    gray3: string;
    gray4: string;
    gray5: string;
    white: string;
    green: string;
    auxiliaryGreen: string;
    greenAccent: string;
    irishGreen: string;
    greenWhatsapp: string;
    greenLight: string;
    seaGreen: string;
    greenPicpay: string;
    greenLime: string;
    darkGreen: string;
    lightRed: string;
    red: string;
    redAccent: string;
    auxiliaryRed: string;
    orange: string;
    auxiliaryOrange: string;
    rideWithGPSOrange: string;
    darkOrange: string;
  };
}

export const lightTheme: IDefaultTheme = {
  colors: {
    hotPink: '#ED5088',
    purple: '#88498F',
    lightPurple: '#A9ACFF',
    blue: '#0564FF',
    auxiliaryBlue: '#4595EC',
    auxiliaryBlueLight: '#DAEAFB',
    blueAccent: '#1A71FF',
    navyBlue: '#004CC8',
    mayaBlue: '#5CC4FF',
    superBlueLight: '#F6F9FF',
    darkBlue: '#0564ff14',
    aliceBlue: '#F0F6FF',
    lightCyan: '#d1e2ff',
    lightYellow: '#fff3cc',
    yellow: '#FFC502',
    yellowAccent: '#F7B32B',
    gold: '#ED9E27',
    goldAccent: '#AA8A1D',
    black: '#000000',
    blackText: '#161C25',
    modalBackground: 'rgba(22, 28, 37, 0.8)',
    pageBackground: '#F7F8FA',
    grayBorder: '#e1e3e6',
    grayWorkshopDashboard: '#818999',
    grayPlaceholder: '#8D8D99',
    gray1: '#1F2A3D',
    gray2: '#565C66',
    gray3: '#BBC0C7',
    gray4: '#E3E4E5',
    gray5: '#FAFAFA',
    white: '#FFFFFF',
    green: '#1FBF55',
    auxiliaryGreen: '#19A949',
    greenAccent: '#009D33',
    irishGreen: '#4DA025',
    darkGreen: '#0B6156',
    greenWhatsapp: '#4CAF50',
    greenLight: '#D1EEDB',
    seaGreen: '#26C967',
    greenPicpay: '#12C75E',
    greenLime: '#A9CB00',
    lightRed: '#ffdadb',
    red: '#F84040',
    redAccent: '#FF5500',
    auxiliaryRed: '#DD3B52',
    orange: '#FF9029',
    auxiliaryOrange: '#ee792b',
    rideWithGPSOrange: '#FA6400',
    darkOrange: '#D86D25'
  }
};

export const darkTheme: IDefaultTheme = {
  colors: {
    hotPink: '#ED5088',
    purple: '#88498F',
    lightPurple: '#A9ACFF',
    blue: '#0564FF',
    auxiliaryBlue: '#4595EC',
    auxiliaryBlueLight: '#DAEAFB',
    blueAccent: '#1A71FF',
    navyBlue: '#004CC8',
    mayaBlue: '#5CC4FF',
    superBlueLight: '#F6F9FF',
    darkBlue: '#0564ff14',
    aliceBlue: '#F0F6FF',
    lightCyan: '#d1e2ff',
    lightYellow: '#fff3cc',
    yellow: '#FFC502',
    yellowAccent: '#F7B32B',
    gold: '#ED9E27',
    goldAccent: '#AA8A1D',
    black: '#000',
    blackText: '#161C25',
    modalBackground: 'rgba(22, 28, 37, 0.8)',
    pageBackground: '#F7F8FA',
    grayBorder: '#e1e3e6',
    grayWorkshopDashboard: '#818999',
    grayPlaceholder: '#8D8D99',
    gray1: '#1F2A3D',
    gray2: '#565C66',
    gray3: '#BBC0C7',
    gray4: '#E3E4E5',
    gray5: '#FAFAFA',
    white: '#FFFFFF',
    green: '#1FBF55',
    auxiliaryGreen: '#19A949',
    greenAccent: '#009D33',
    irishGreen: '#4DA025',
    darkGreen: '#0B6156',
    greenWhatsapp: '#4CAF50',
    greenLight: '#D1EEDB',
    seaGreen: '#26C967',
    greenPicpay: '#12C75E',
    greenLime: '#A9CB00',
    lightRed: '#ffdadb',
    red: '#F84040',
    redAccent: '#FF5500',
    auxiliaryRed: '#DD3B52',
    orange: '#FF9029',
    auxiliaryOrange: '#ee792b',
    rideWithGPSOrange: '#FA6400',
    darkOrange: '#D86D25'
  }
};
