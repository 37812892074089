import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';

import Theme from '@source/styles/theme';
import ApplicationProvider from '@source/contexts/ApplicationProvider';
import apolloClient from '@source/lib/apollo.client';
import { QueryClientProvider } from '@tanstack/react-query';
import reactQueryClient from '@source/lib/reactQuery.client';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const Scripts = dynamic(() => import('@source/components/Scripts'), {
  ssr: false
});

import { getNProgressColor } from '@source/helpers/getNProgressColor';
const NextNProgress = dynamic(() => import('nextjs-progressbar'), {
  ssr: false
});

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-tooltip/dist/react-tooltip.css';

import GlobalStyle from '@styles/globals';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import { CacheProvider as EmotionCacheProvider } from '@emotion/react';

import createEmotionCache from '@source/utils/createEmotionCache';
const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  // emotionCache = cache,
  pageProps: { session, ...pageProps }
}) {
  const router = useRouter();

  return (
    <>
      <GlobalStyle />
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <EmotionCacheProvider value={emotionCache}>
          <Theme>
            <NextNProgress
              color={getNProgressColor(router.asPath)}
              startPosition={0.3}
              stopDelayMs={300}
              height={3}
              showOnShallow={true}
              options={{ showSpinner: false }}
            />
            <ApolloProvider client={apolloClient}>
              <QueryClientProvider client={reactQueryClient}>
                <ApplicationProvider>
                  <SessionProvider session={session}>
                    <Component {...pageProps} key={router.asPath} />
                  </SessionProvider>
                </ApplicationProvider>
              </QueryClientProvider>
            </ApolloProvider>
          </Theme>
          <Scripts />
        </EmotionCacheProvider>
      </StyledEngineProvider>
    </>
  );
}

export default MyApp;
