import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  margin-top: 4rem;
`;

export const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 3.2rem;
`;

export const Text = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 2.4rem;
`;

export const Anchor = styled.a`
  background: transparent;
  border: ${({ theme }) => `2px solid ${theme.colors.blue}`};
  color: ${({ theme }) => theme.colors.blue};
  margin-top: 1.6rem;
  border-radius: 10rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;

  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
`;
