import { atom } from 'recoil';

export type ToastData = {
  open: boolean;
  severity: 'error' | 'success' | 'warning' | 'info';
  message: string;
  duration: number;
};

export const toastState = atom<ToastData>({
  key: 'toastState',
  default: {
    open: false,
    severity: 'success',
    message: '',
    duration: 3000
  }
});
