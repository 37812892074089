import { useToast } from '@source/recoil/useToast/hook';
import CustomAlert from '../CustomAlert';

type ToastProviderProps = {
  children: React.ReactNode;
};

const ToastProvider: React.FC<ToastProviderProps> = ({
  children
}): JSX.Element => {
  const { toastData, closeToast } = useToast();

  return (
    <>
      {children}
      {toastData.open && (
        <CustomAlert
          open={toastData.open}
          onClose={() => closeToast()}
          severity={toastData.severity}
          message={toastData.message}
          autoHideDuration={toastData.duration}
        />
      )}
    </>
  );
};

export default ToastProvider;
