import { useRouter } from 'next/router';

import CheckIcon from '@components/SVGs/checkIcon';
import LabelIcon from '@components/Navbar/LabelIcon';
import { CartAndStepController } from '@source/recoil/types';
import { useCartAndStepsController } from '@source/recoil/hooks';

type Props = {
  stepItem: CartAndStepController;
};

const StepIcon: React.FC<Props> = ({ stepItem }): JSX.Element => {
  const { cartAndStepsController } = useCartAndStepsController();

  const router = useRouter();
  const [, , currentPage] = router.asPath.split('/');

  const currentStep =
    currentPage === 'voucher'
      ? 1
      : cartAndStepsController.find(step => step.name === currentPage)?.step;

  if (stepItem.checked) {
    return <CheckIcon />;
  }

  const isDisabled =
    Number(stepItem.step) > Number(currentStep) ? 'disabled' : undefined;

  return <LabelIcon step={stepItem.step as number} status={isDisabled} />;
};

export default StepIcon;
