import styled from 'styled-components';

export const Wrapper = styled.div`
  background: red;
  position: fixed;
  bottom: 0;
  width: 100%;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px 12px 0px 0px;

  padding: 1.6rem 2.4rem;

  z-index: 100;
  animation: appearDownloadAppDrawer 400ms ease-out forwards;

  @keyframes appearDownloadAppDrawer {
    from {
      visibility: hidden;
      opacity: 0;
      transform: translateY(25rem);
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;

export const CloseButton = styled.button`
  display: block;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  border: none;
  margin-left: auto;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ContentTitle = styled.div`
  flex: 1;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: ${({ theme }) => theme.colors.blackText};
`;

export const TitleHelper = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const Anchor = styled.a`
  width: 7.5rem;
  height: 3.6rem;
  background: ${({ theme }) => theme.colors.grayBorder};
  border: none;
  border-radius: 10rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.blue};

  display: grid;
  place-items: center;

  transition: all 200ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;
