import { useRecoilState } from 'recoil';
import type { ToastData } from './atom';
import { toastState } from './atom';

interface ICloseToast {
  (): void;
}

export interface IHandleToast {
  (
    severity: 'error' | 'success' | 'warning' | 'info',
    message: string,
    duration?: number
  ): void;
}

interface IUseToast {
  (): {
    toastData: ToastData;
    closeToast: ICloseToast;
    handleToast: IHandleToast;
  };
}

const defaultToastData: ToastData = {
  open: false,
  severity: 'success',
  message: '',
  duration: 3000
};

export const useToast: IUseToast = () => {
  const [toastData, setToastData] = useRecoilState<ToastData>(toastState);

  const closeToast: ICloseToast = () => {
    setToastData(defaultToastData);
  };

  const handleToast: IHandleToast = (severity, message, duration = 3000) => {
    setToastData({
      open: true,
      severity,
      message,
      duration
    });
  };

  return { toastData, closeToast, handleToast };
};
